.reservation {
  background-image: url("../../../../Assets/reservation.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 6em;
  position: relative;
}
.reservation .reservation-gradient {
  height: 100%;
  width: 100%;
  background-image: -moz-linear-gradient(270deg, transparent 0%, #00000027 80%, #2c2c2ccc 100%);
  background-image: -webkit-gradient(270deg, transparent 0%, #00000027 80%, #2c2c2ccc 100%);
  background-image: -webkit-linear-gradient(270deg, transparent 0%, #00000027 80%, #2c2c2ccc 100%);
  background-image: -o-linear-gradient(270deg, transparent 0%, #00000027 80%, #2c2c2ccc 100%);
  background-image: -ms-linear-gradient(270deg, transparent 0%, #00000027 80%, #2c2c2ccc 100%);
  background-image: linear-gradient(270deg, transparent 0%, #00000027 80%, #2c2c2ccc 100%);
  position: absolute;
}
.reservation .reservation-box {
  display: flex;
  background-color: #151515;
  padding-bottom: 2rem;
  max-width: 30rem;
  margin: 6rem auto 0 auto;
  justify-content: center;
}
.reservation .reservation-box .reservation-box__content {
  width: 100%;
}
.reservation .reservation-box .reservation-box__content .reservation-box__header {
  background-color: #e2a240;
  padding: 0.5rem 1.5rem;
  text-align: center;
  color: white;
  margin-bottom: 3rem;
}
.reservation .reservation-box .reservation-box__content .reservation-box__header .header__banner {
  font-size: 35px;
}
@media screen and (max-width: 620px) {
  .reservation .reservation-box .reservation-box__content .reservation-box__header .header__banner {
    font-size: 25px;
  }
}
.reservation .reservation-box .reservation-box__content .reservation-box__header .header__detail {
  font-size: 14px;
}
@media screen and (max-width: 400px) {
  .reservation .reservation-box .reservation-box__content .reservation-box__header {
    width: 100%;
  }
}
.reservation .reservation-box .reservation-box__content .reservation-box__form {
  width: 90%;
  margin: 0 auto;
  color: white;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form input {
  border-radius: 0;
  padding: 1.5rem 1rem;
  background-color: #444;
  border: 0.1rem solid #555;
  color: white;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  border-radius: 3rem;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form input::placeholder {
  color: hsl(0, 0%, 50%);
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .fourthLine .opening-information {
  font-size: 14px;
  padding-top: 30px;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box {
  text-align: right;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box .submitButton {
  color: #151515;
  font-size: 1.2rem;
  border-radius: 3rem;
  padding: 0.7rem 1.5rem;
  border: 0;
  background-color: orange;
  transition: all 0.3s linear;
  outline: none !important;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box .submitButton:hover {
  background-color: #cc8400;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box .moreInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box .moreInfo .moreInfo__icon {
  margin-right: 5px;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box .moreInfo .moreInfo__text {
  font-size: 13px;
  margin-top: 5px;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box .moreInfo:hover {
  cursor: pointer;
}
@media screen and (max-width: 410px) {
  .reservation .reservation-box .reservation-box__content {
    width: 100%;
  }
}
