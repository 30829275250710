.locations-page {
  width: 100%;
  background-image: url("../../../../Assets/pattern.jpg");
  margin-top: -10rem;
}
@media screen and (max-width: 1199px) {
  .locations-page {
    padding-top: 60px;
  }
}
.locations-page .card-container {
  padding: 2rem;
  width: 50%;
}
.locations-page .locations {
  padding-top: 15rem;
  padding-bottom: 4rem;
  display: flex;
  margin: 0 auto;
  justify-content: flex-start;
  align-items: center;
  max-width: 1800px;
  color: white;
  flex-wrap: wrap;
}
.locations-page .locations__card {
  width: 100%;
  background-color: #151515;
}
.locations-page .locations__card--image {
  border: 0.2rem solid white;
  width: 100%;
  height: auto;
}
.locations-page .locations__card--image img {
  max-width: 100%;
  height: auto;
}
.locations-page .locations__card--text {
  padding: 1rem 1.5rem;
  font-size: 3rem;
  text-align: center;
}
.locations-page .locations__card--description {
  font-size: 1.4rem;
  text-align: justify;
  padding: 0rem 2.5rem 0 2.5rem;
  min-height: 14rem;
}
@media screen and (max-width: 1250px) {
  .locations-page .locations__card--text {
    font-size: 2rem;
  }
  .locations-page .locations__card--description {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 991px) {
  .locations-page .locations__card--description {
    font-size: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .locations-page .locations .card-container {
    width: 100%;
  }
  .locations-page .locations__card--description {
    min-height: 10rem;
    padding-bottom: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .locations-page .locations .card-container {
    padding: 1rem 0;
  }
}
