.mobileMenu {
  width: 100%;
  margin: 0 auto;
}
.mobileMenu .eu-logo {
  position: fixed;
  right: 0;
  top: 3.5rem;
  z-index: -1;
}
.mobileMenu .eu-logo img {
  max-width: 270px;
  height: auto;
}
.mobileMenu .mobileMenu__header {
  background-color: white;
  color: black;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  height: 5em;
}
.mobileMenu .mobileMenu__header .header-logo {
  display: flex;
  align-items: center;
}
.mobileMenu .mobileMenu__header .header-logo img {
  max-width: 7rem;
  height: auto;
}
.mobileMenu .mobileMenu__header .header__toggleButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 2rem;
}
.mobileMenu .mobileMenu__header .header__toggleButton:hover {
  cursor: pointer;
}
.mobileMenu .mobileMenu__header .header__toggleButton i:before {
  font-size: 35px;
  margin-left: 0;
}
@media screen and (max-width: 400px) {
  .mobileMenu .mobileMenu__header {
    height: 3.5rem;
  }
  .mobileMenu .mobileMenu__header .header-logo {
    display: flex;
    align-items: center;
  }
  .mobileMenu .mobileMenu__header .header-logo img {
    cursor: pointer;
    max-width: 5rem;
    height: auto;
  }
  .mobileMenu .mobileMenu__header .header__toggleButton i:before {
    font-size: 20px;
  }
}
.mobileMenu .mobile-menubar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  background-color: white;
  color: black;
  font-size: 20px;
}
.mobileMenu .mobile-menubar .mobile-menubar__item {
  margin: 10px 30px 0 30px;
}
.mobileMenu .mobile-menubar .mobile-menubar__item p a {
  text-transform: uppercase;
}
.mobileMenu .mobile-menubar .mobile-menubar__item a:active,
.mobileMenu .mobile-menubar .mobile-menubar__item a:hover,
.mobileMenu .mobile-menubar .mobile-menubar__item a:visited,
.mobileMenu .mobile-menubar .mobile-menubar__item a:link {
  color: black;
  text-decoration: none;
}
.mobileMenu .mobile-menubar .mobile-menubar-order-button {
  margin-top: 0;
  padding-bottom: 10px;
}
.mobileMenu .mobile-menubar .mobile-menubar-order-button .menubar__order-button {
  background-color: orange;
  color: black;
  padding: 10px 20px;
  border-radius: 40px 40px 40px 40px;
  -moz-border-radius: 40px 40px 40px 40px;
  -webkit-border-radius: 40px 40px 40px 40px;
  border: 0px solid #000000;
}
.mobileMenu .mobile-menubar .mobile-menubar-order-button .menubar__order-button:hover {
  background-color: #ffae1a;
}
.mobileMenu .mobile-menubar .mobile-menubar__order {
  margin: 10px 30px 0 30px;
}
.mobileMenu .mobile-menubar .mobile-menubar__order .order-button {
  color: #28a745;
  border: 2px solid #28a745;
  padding: 5px 35px 5px 35px;
  border-radius: 25px 25px 25px 25px;
}
.mobileMenu .mobile-menubar .mobile-menubar__order .order-button:hover {
  cursor: pointer;
}
.mobileMenu .mobile-menubar .mobile-menubar__langSel {
  margin: 10px 30px 10px 30px;
}
.mobileMenu .mobile-menubar .mobile-menubar__langSel span {
  cursor: pointer;
}
@media screen and (min-width: 1201px) {
  .mobileMenu {
    display: none;
  }
}
.sticky {
  width: 100%;
}
