.menu-wrapper {
  color: white;
  background-image: url("../../../../Assets/pattern.jpg");
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5rem;
}
.menu-wrapper h1 {
  font-size: 3rem;
}
.menu-wrapper .menu-content {
  width: 800px;
}
@media only screen and (max-width: 1200px) {
  .menu-wrapper .menu-content {
    width: 300px;
  }
}
.menu-wrapper .menu-content h2 {
  text-align: left;
  width: 100%;
  color: orange;
  border-bottom: 5px dotted grey;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  padding-top: 2rem;
}
.menu-wrapper .menu-content h3 {
  padding: 1rem 0;
  margin-bottom: 1rem;
  border-bottom: 2px dotted #525252;
}
.menu-wrapper .menu-item {
  display: flex;
  width: 100%;
  padding-bottom: 1rem;
}
.menu-wrapper .menu-item__left,
.menu-wrapper .menu-item__right {
  flex: 0 0 50%;
}
.menu-wrapper .menu-item__left {
  text-align: left;
}
.menu-wrapper .menu-item__left p {
  color: #a0a0a0;
  font-size: 14px;
}
.menu-wrapper .menu-item__right {
  text-align: right;
  font-size: 1.5rem;
}
.menu-wrapper .spinner-wrapper {
  margin: 2rem auto;
  width: 4rem;
  text-align: center;
}
