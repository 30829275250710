.homePage {
  position: relative;
  background-image: url("../../../../Assets/banner.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  padding-top: 60px;
  background-color: #151515 1;
}
.homePage .above {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
@media screen and (max-width: 1199px) {
  .homePage {
    background-image: url("../../../../Assets/banner_small.jpg");
    background-position: center 60px;
    padding-top: 80px;
  }
}
@media screen and (max-width: 1421px) {
  .homePage {
    padding-top: 0;
  }
}
@media screen and (max-width: 400px) {
  .homePage {
    background-position: center 50px;
  }
}
.homePage .under {
  padding-top: 30em;
}
@media screen and (max-width: 1199px) {
  .homePage .under {
    padding-top: 20em;
  }
}
.no-bg-wrapper {
  background-image: none !important;
}
