.footer {
    background-color: #151515;
    color: white;
    padding-top: 3em;
}
.footer .footer__logo {
    display: flex;
    justify-content: flex-end;
}
@media screen and (max-width: 1199px) {
    .footer .footer__logo {
        justify-content: center;
    }
}
.footer .footer__middleContent .footer__middleContent__above {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
}
.footer .footer__middleContent .footer__middleContent__above .middleContent__title {
    font-size: 20px;
}
.footer .footer__middleContent .footer__middleContent__above .middleContent__detailIcons {
    display: inline;
}
.footer .footer__middleContent .footer__middleContent__above .middleContent__detailIcons a {
    color: white;
}
.footer .footer__middleContent .footer__middleContent__above .middleContent__detailIcons a:hover {
    color: #bbb;
}
.footer .footer__middleContent .footer__middleContent__above .middleContent__detailIcons i {
    margin-right: 20px;
}
@media screen and (max-width: 600px) {
    .footer .footer__middleContent .footer__middleContent__above {
        flex-direction: column;
    }
}
.footer .footer__middleContent .footer__middleContent__under {
    margin-top: 30px;
}
.footer
    .footer__middleContent
    .footer__middleContent__under
    .footer__middleContent__under__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.footer
    .footer__middleContent
    .footer__middleContent__under
    .footer__middleContent__under__content
    .copyright-icon {
    margin-right: 5px;
}
.footer
    .footer__middleContent
    .footer__middleContent__under
    .footer__middleContent__under__content
    .copyright-icon
    i:before {
    font-size: 12px;
}
.footer
    .footer__middleContent
    .footer__middleContent__under
    .footer__middleContent__under__content
    .copyright-text {
    margin-top: 2px;
    margin-right: 25px;
    font-size: 14px;
}
